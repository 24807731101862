// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-game-js": () => import("./../src/pages/game.js" /* webpackChunkName: "component---src-pages-game-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-roles-js": () => import("./../src/pages/roles.js" /* webpackChunkName: "component---src-pages-roles-js" */),
  "component---src-pages-stages-js": () => import("./../src/pages/stages.js" /* webpackChunkName: "component---src-pages-stages-js" */)
}

